module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M9C8724","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/10a-cookieyes/gatsby-browser.js'),
      options: {"plugins":[],"id":"7712f0fd34b8c5a9d828ca2b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.10adventures.com/?graphql","schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":5,"timeout":180000},"html":{"createStaticFiles":false,"useGatsbyImage":false},"production":{"allow404Images":true},"type":{"__all":{"limit":null},"Comment":{"exclude":true},"MediaItem":{"createFileNodes":false,"lazyNodes":true,"localFile":{"requestConcurrency":5,"maxFileSizeBytes":1}},"ActivityType":{"fields":{"descriptionRendered":{"variables":"format: HTML"}}},"Region":{"fields":{"descriptionRendered":{"variables":"format: HTML"},"activityTypeDetails.nodes.description":{"variables":"format: HTML"},"nearbyRegionIds":{"variables":"idType: GRAPHQL_ID"}}},"Route":{"fields":{"directions":{"variables":"format: HTML"},"description":{"variables":"format: HTML"},"summary":{"variables":"format: HTML"},"hints":{"variables":"format: HTML"},"nearbyRouteIds":{"variables":"idType: GRAPHQL_ID"}}},"FeaturedContentGroup":{"fields":{"descriptionRendered":{"variables":"format: HTML"},"footerText":{"variables":"format: HTML"}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
