import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { document, exists } from "browser-monads"

const Overlay = styled.div`
  position: fixed;
  z-index: ${props =>
    props.level === 2
      ? props.theme.layers.overlay2
      : props.theme.layers.overlay1};
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props =>
    props.light ? props.theme.white : props.theme.black};
  opacity: 0.4;

  display: none;
  bottom: 100%;

  &.active {
    display: block;
    bottom: 0;
  }
`

export const LayerContext = React.createContext({
  open: () => {},
  close: () => {},
  isActive: () => {},
})

// controls window/document-level scrolling
const scrolling = {
  disable: () => {
    if (exists(document)) {
      document.documentElement.style.overflow = "hidden"
    }
  },
  enable: () => {
    if (exists(document)) {
      document.documentElement.style.overflow = "visible"
    }
  },
}

export const LayerProvider = ({ children }) => {
  const [activeLayers, setActiveLayers] = useState([])
  const [overlayLevel, setOverlayLevel] = useState(1)
  const [overlayLight, setOverlayLight] = useState(false)
  const [overlayLock, setOverlayLock] = useState(false)

  const closeAllLayers = useCallback(() => {
    // call the onClose for any activeLayers that have it
    activeLayers.forEach(activeLayer => {
      if (activeLayer.onClose && typeof activeLayer.onClose === "function") {
        activeLayer.onClose()
      }
    })
    // clear out the activeLayers list
    setActiveLayers([])

    // re-enable document scrolling
    scrolling.enable()
  }, [activeLayers, setActiveLayers])

  const handleOverlayClick = useCallback(() => {
    if (!overlayLock) {
      closeAllLayers()
    }
  }, [overlayLock, closeAllLayers])

  const LayersAPI = {
    open: (layer, queue = false) => {
      // validate the layer
      if (!layer.layerId) {
        return
      }
      // do nothing if the layer is already open
      if (LayersAPI.isActive(layer.layerId)) {
        return
      }
      setOverlayLevel(layer.level || 1)
      setOverlayLight(!!layer.light)
      setOverlayLock(!!layer.lock)

      // check if it's an exclusive open, or if it queues with other layers
      // TODO: this may turn into some kind of grouping mechanism (ex. keep modals open while header group is active)
      if (queue) {
        // open the new layer while keeping others open
        setActiveLayers([layer, ...activeLayers])
      } else {
        // close all other layers before opening the new one
        closeAllLayers()
        setActiveLayers([layer])
      }

      // disable document scrolling while a layer is active
      scrolling.disable()
    },
    close: layerId => {
      const activeLayer = activeLayers.find(check => check.layerId === layerId)
      if (activeLayer) {
        if (activeLayer.onClose && typeof activeLayer.onClose === "function") {
          activeLayer.onClose()
        }
        // build the new activeLayers
        const newActiveLayers = activeLayers.filter(
          check => check !== activeLayer
        )
        // set state
        setActiveLayers(newActiveLayers)

        // check if any layers still active
        if (!newActiveLayers.length) {
          // re-enable document scrolling if all layers are closed
          scrolling.enable()
        }
      }
    },
    isActive: layerId => activeLayers.find(check => check.layerId === layerId),
  }

  return (
    <LayerContext.Provider value={LayersAPI}>
      <Overlay
        className={activeLayers.length ? "active" : ""}
        level={overlayLevel}
        light={overlayLight}
        onClick={handleOverlayClick}
      />
      {children}
    </LayerContext.Provider>
  )
}

LayerProvider.propTypes = {
  children: PropTypes.node,
}
